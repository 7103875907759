import React, { useEffect, useState } from 'react';
import './scss/LoginContainer.scss';
import { useSelector } from 'react-redux';
import { FormProps } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import LoginService from './store/LoginService';
import { StorageInterface } from '../../../store/StorageInterface';
import LoadingComponent from '../../reusables/Loading/LoadingComponent';
import MailVerificationComponent from '../MailVerification/components/MailVerification/MailVerificationComponent';
import MessageComponent from '../../reusables/Message/MessageComponent';
import LoginComponent from './components/Login/LoginComponent';
import { IMailVerificationComponentProps } from '../MailVerification/components/MailVerification/interfaces/MailVerificationComponentInterfaces';
import { ILoginComponentProps } from './components/Login/interfaces/LoginComponentInterfaces';

import { errorLogin } from '../../../store/AuthSlice';
import storage from '../../../store/storage';
import AppService from '../../../store/AppService';
import HeadingComponent from '../../reusables/Heading/HeadingComponent';

// import { ReactComponent as Decorator } from '../../../resources/img/decorator.svg';

function LoginContainer({ type }: IMailVerificationComponentProps) : JSX.Element {
  const [showErrorBag, setShowErrorBag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('ejemplo@mail.com');
  const [resendData, setResendData] = useState({});

  const search = useLocation();
  const callback = new URLSearchParams(search?.search).get('callback');
  const landing = new URLSearchParams(search?.search).get('landing');
  const referral = new URLSearchParams(search?.search).get('referral');

  const authSlice = useSelector(
    (state: StorageInterface) => state.authSlice,
  );
  const appSlice = useSelector(
    (state: StorageInterface) => state.appSlice,
  );
  const headerSlice = useSelector(
    (state: StorageInterface) => state.headerSlice,
  );

  const LoginComponentProps:ILoginComponentProps = {
    showErrorBag,
    errorMessage,
    socialLogin: (socialType: string) => { LoginService.socialLogin(socialType); },
    cancelAction: () => { console.log('cancel'); },
    isRetail: headerSlice?.isRetailHost,
    refreshToken: authSlice?.refreshToken,
    onSubmit: async (data: FormProps) => {
      setResendData({ ...data });
      loginSend({
        ...data, callback, landing, referral,
      }, false);
    },
    onSubmitResendConfirmationCode: async () => {
      resendConfirmationCode();
    },
  };

  useEffect(() => {
    AppService.getToken();
  }, []);

  useEffect(() => {
    if (window.location.hash && type === 'social') {
      const hash = window.location.hash.substring(1);
      const [accessToken, state] = hash.split('&');
      const [keyState, valueState] = state.split('=');
      const [keyToken, valueToken] = accessToken.split('=');

      if (keyState === 'state' && sessionStorage.getItem('statetoken') === valueState && keyToken === 'access_token') {
        loginSend({
          token: valueToken, callback, landing, referral,
        }, true);
      } else {
        storage.dispatch(errorLogin({ loggedIn: false, error: true, message: LoginService.msg.error }));
        setShowErrorBag(true);
        setErrorMessage(LoginService.msg.error);
      }
    } else {
      loginCheck();
    }
  }, []);

  useEffect(() => {
    if (authSlice?.loggedIn && authSlice?.verified) {
      setTimeout(() => {
        window.location.replace(authSlice.redirectTo);
      }, 1500);
    }
  }, [authSlice?.loggedIn, appSlice?.intendedUrl, authSlice?.verified, authSlice?.refreshToken]);

  async function loginSend(data: FormProps, hasSocial = false) {
    const result = await LoginService.login(data, hasSocial);
    if (result.success && result.data != null) {
      setUser(result.data.name);
      setEmail(result.data.email);
    }
    setShowErrorBag(!result.success);
    setErrorMessage(result.error);
    AppService.setLoadingApp(false);
  }

  async function loginCheck() {
    const result = await LoginService.verifyAccessToken();
    if (result.success && result.data != null) {
      setUser(result.data.name);
      setEmail(result.data.email);
    }
    AppService.setLoadingApp(false);
  }

  async function resendConfirmationCode() {
    const result = await LoginService.resendConfirmationCode(resendData);
    if (result.success) {
      setShowErrorBag(false);
    } else {
      setShowErrorBag(!result.success);
      setErrorMessage(result.error);
    }
    AppService.setLoadingApp(false);
  }

  return (
    <div className="LoginContainer">
      {authSlice?.loggedIn && !authSlice?.verified && (
        <MailVerificationComponent type="account" email={email} />
      )}
      {!authSlice?.loggedIn && (
        <div className="container-af m-auto d-block d-lg-flex justify-content-center pb-5 mb-4 pt-lg-5 mt-lg-3">
          <HeadingComponent />
          {appSlice?.loading && (<LoadingComponent />)}
          <LoginComponent {...LoginComponentProps} />
        </div>
      )}
      {authSlice?.loggedIn && authSlice?.verified && (
        <MessageComponent
          type="success"
          text={`¡Hola ${user}, bienvenido(a) a Autofact!`}
          subtext="En estos momentos estás siendo dirigido(a) a tu cuenta."
          spinner
          button=""
        />
      )}
    </div>
  );
}

export default LoginContainer;
